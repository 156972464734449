import React from "react";
import CarbonpaperLogo from "./../images/carbonpaper-logo.svg";

const TermsAndConditions = () => {
  return (
    <div className="bg-brand-darkgray" style={styles.compliancePage}>
      <div style={styles.headerContainer}>
        <a style={styles.headerContainer} href="/">
          <img className="w-20" src={CarbonpaperLogo} alt="CP" />
          <p style={styles.headerText}>DIGIALT CARBONPAPER APPS PRIVATE LIMITED</p>
        </a>
      </div>
      <div style={styles.complianceContent}>
        <div style={styles.contentContainer}>
          <p style={styles.contentHead}>Terms &amp; Conditions</p>
          <div style={styles.contentSeparator}></div>
          <p style={styles.updatedDate}>Last updated on Mar 30th 2024</p>
          <p style={styles.contentText}>
            For the purpose of these Terms and Conditions, The term "we", "us",
            "our" used anywhere on this page shall mean
            DIGIALT CARBONPAPER APPS PRIVATE LIMITED, whose registered/operational
            office is Unit 101, Oxford Towers, 139, HAL Old Airport Rd, Hulsur Bazaar, Bangalore North
            Bengaluru KARNATAKA 560008 . "you", “your”, "user", “visitor” shall
            mean any natural or legal person who is visiting our website and/or
            agreed to purchase from us.
          </p>
          <p style={styles.contentText}>
            <strong>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</strong>
          </p>
          <ul style={styles.unorderList}>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                The content of the pages of this website is subject to change without notice.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness or suitability of the information and materials
                found or offered on this website for any particular purpose.
                You acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for
                any such inaccuracies or errors to the fullest extent permitted
                by law.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                Your use of any information or materials on our website and/or product
                pages is entirely at your own risk, for which we shall not be liable.
                It shall be your own responsibility to ensure that any products, services
                or information available through our website and/or product pages meet your
                specific requirements.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                Our website contains material which is owned by or licensed to us. This material
                includes, but is not limited to, the design, layout, look, appearance,
                and graphics. Reproduction is prohibited other than in accordance with the
                copyright notice, which forms part of these terms and conditions.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                All trademarks reproduced in our website which are not the property of, or
                licensed to, the operator are acknowledged on the website.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                Unauthorized use of information provided by us  shall give rise to a claim for
                damages and/or be a criminal offense.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                From time to time our website may also include links to other websites. These links are
                provided for your convenience to provide further information.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                You may not create a link to our website from another website or document without
                DIGIALT CARBONPAPER APPS PRIVATE LIMITED’s prior written consent.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                Any dispute arising out of use of our website and/or purchase with
                us and/or any engagement with us is subject to the laws of India.
              </p>
            </li>
            <li style={styles.listItem}>
              <p style={styles.contentText}>
                We, shall be under no liability whatsoever in respect of any loss or
                damage arising directly or indirectly out of the decline of
                authorization for any Transaction, on Account of the Cardholder
                having exceeded the preset limit mutually agreed by us with our
                acquiring bank from time to time.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const styles = {
  body: {
    margin: 0,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
  },
  headerText: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#fff',
    margin: '0 0 0 18px',
  },
  compliancePage: {
    // background: '#0a1d38',
    fontFamily: 'Lato, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  contentContainer: {
    margin: '0 auto',
    maxWidth: '1000px',
  },
  complianceContent: {
    background: '#ffffff',
    borderRadius: '32px 32px 0 0',
    padding: '32px 24px',
  },
  contentHead: {
    fontSize: '24px',
    lineHeight: '30px',
    color: '#213554',
    margin: 0,
    fontWeight: '700',
  },
  contentSeparator: {
    width: '28px',
    height: '5px',
    backgroundColor: '#213554',
    marginTop: '16px',
  },
  updatedDate: {
    margin: '16px 0 0',
    color: '#213554ab',
    fontWeight: '700',
  },
  contentText: {
    color: '#515978',
    margin: '16px 0 0',
    fontSize: '14px',
    lineHeight: '20px',
  },
  merchantLogo: {
    width: '74.6px',
    height: '64px',
    background: '#fefefe',
    boxShadow: '0px 0px 8px #00000040',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    background: '#0a1d38',
    boxShadow: '11.0003px 22.0007px 53.9016px rgba(0, 0, 0, 0.1), 22.0007px 11.0003px 53.9854px -2.03719px rgba(255, 255, 255, 0.1)',
    borderRadius: '50%',
  },
  logoText: {
    color: '#fff',
    fontWeight: '700',
    fontSize: '32px',
  },
  listItem: {
    display: 'list-item',
    paddingLeft: '5px',
  },
  unorderList: {
    margin: 0,
  },
  listText: {
    marginTop: '8px',
  },
};

export default TermsAndConditions;