import React from "react";
import CarbonpaperLogo from "./../images/carbonpaper-full-logo-cropped.svg";

interface Props {
  logo?: any;
}

function LogoAndText(props: Props) {
  return (
    <div className="flex items-center w-full">
      <img src={props.logo || CarbonpaperLogo} alt="Carbon Paper Logo" className="w-full" />
    </div>
  );
}

export default LogoAndText;
