import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Info } from "./form/Info";
import { useAppSelector } from "../redux/hooks";
import { BulkUploadRequest } from "./login/api";

const BulkPhotoUploader = () => {
  const [photos, setPhotos] = useState<any>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [eventId, setEventId] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const userDetails: any = useAppSelector((state) => state);
  const navigate = useNavigate();


  let { authToken } = userDetails?.userDetails;
  if (!authToken) {
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    authToken = userDetails?.authToken;
  }
  const handlePhotoChange = (e: any) => {
    const files = Array.from(e.target.files);

    const updatedPhotos = files.map((file: any) => ({
      file,
      previewURL: URL.createObjectURL(file),
    }));

    setPhotos([...photos, ...updatedPhotos]);
  };

  const handleUpload = async () => {

    try {

      if (photos.length > 50) {
        alert('Please upload a maximum of 50 photos at a time')
        return
      } else if (photos.length === 0) {
        alert('Please select photos to upload')
        return
      } else if (eventId === '') {
        alert('Please enter the event id')
        return
      } else if (!authToken) {
        alert('Please login to upload photos')
        return
      }

      const formData = new FormData();
      photos.forEach((photo: any, index: any) => {
        formData.append(`files`, photo.file);
      });
      setUploading(true);
      const response = await BulkUploadRequest(eventId, formData, authToken);
      alert(`${response.data.length} photos uploaded successfully`);
      setUploading(false);
      setPhotos([]);
    } catch (e: any) {
      console.error(e);
      alert(`Error : ${e?.response?.data?.error || `An Error Occurred`}`)
      setUploading(false);
      setPhotos([]);
    }
  };

  const handleClearAll = () => {
    if (uploading) {
      return;
    }
    setPhotos([]);
  };

  const handleInputChange = (e: any) => {
    if (uploading) {
      return;
    }
    setEventId(e.target.value);
  };

  const handleDeletePhoto = (index: any) => {
    if (uploading) {
      return;
    }
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };

  React.useEffect(() => {
    if (!authToken) {
      navigate("/login");
    }
  }, []);

  return (
    <div className=" w-full h-full m-0">
      <h1 style={{ textAlign: "center", margin: "20px", fontSize: '20px' }}>
        Bulk Photo Uploader
      </h1>

      <p style={{ textAlign: "left", margin: "20px", fontSize: '20px' }}>
        Please Read Carefully Before Uploading:
      </p>
      <p style={{ textAlign: "left", margin: "20px", fontSize: '16px' }}>
        1. To be able to upload photos, you need to have the event id. You can get the event id from the event hosts.
      </p>
      <p style={{ textAlign: "left", margin: "20px", fontSize: '16px' }}>
        2. To be able to upload photos, you need to be logged in as a host. Kindly request your event hosts to provide you host access if you do not have it yet.
      </p>
      <p style={{ textAlign: "left", margin: "20px", fontSize: '16px' }}>
        3. The server limits the file upload size to 8MB per photo. If you have photos larger than 8MB, please resize them before uploading.
      </p>
      <p style={{ textAlign: "left", margin: "20px", fontSize: '16px' }}>
        4. The server limits the number of photos to be uploaded at once to 50 photos. If you have more than 50 photos, please upload them in batches of 50.
      </p>
      <p style={{ textAlign: "left", margin: "20px", fontSize: '16px' }}>
        5. To view the uploaded photos, please visit the event gallery on the mobile app.
      </p>
      <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
        <input type="text" placeholder="Enter event id" className="input input-bordered w-full max-w-xs" onChange={handleInputChange} />
      </div>
      {/* <input style={{ display: "block", margin: "auto", border: "2px solid white" }} type="file" accept=".jpg,.jpeg,.png" multiple onChange={handlePhotoChange} /> */}
      <div className="flex justify-center my-10">
        <input type="file" className="file-input file-input-bordered " accept=".jpg,.jpeg,.png" multiple onChange={handlePhotoChange} name="sadf" />
        <br />

      </div>
      <div className="mx-auto text-center my-10" style={{ fontSize: '20px' }}>{photos.length} files selected in total.</div>

      <div style={{ textAlign: "center", margin: "10px 0" }}>
        <button className="btn btn-neutral mx-5" disabled={uploading} onClick={handleUpload}>
          Upload {
            uploading && <span className="loading loading-infinity loading-lg"></span>
          }

        </button>
        <button className="btn btn-neutral mx-5" disabled={uploading} onClick={handleClearAll}>
          Clear All
        </button>
      </div>
      {photos.length > 0 && <Info message={"Make sure you have selected the correct photos"} />}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {photos.map((photo: any, index: any) => (
          <div key={index} style={{ margin: "10px", position: "relative", width: "220px", height: "220px" }}>
            <img src={photo.previewURL} alt={`Thumbnail ${index}`} style={{ border: "1px solid gray" }} />
            <button className="btn btn-circle btn-outline absolute top-0 right-0 bg-brand-lightgray" onClick={() => handleDeletePhoto(index)} >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BulkPhotoUploader;
