import React, { useEffect } from 'react';
import { useState } from 'react'
import { Calendar, Clock, MapPin, ArrowLeft } from 'lucide-react'
import { DayList } from './DayList';
import { ActivityList } from './ActivityList';
import { InclusionsList } from './InclusionsList';
import moment from 'moment';
import ActivityDetails from './ActivityDetails';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { FetchItinerary } from './api/ItineraryApi';

export default function ItineraryViewer() {

  const { itineraryId } = useParams<{ itineraryId: string }>();

  const { data: eventData, isSuccess, isLoading, isFetching, error } = useQuery({
    staleTime: 10000,
    queryKey: ["itineraryData", itineraryId],
    queryFn: () => {
      return itineraryId && FetchItinerary(itineraryId) || null;
    },
    enabled: !!itineraryId
  });

  const [selectedDayId, setSelectedDayId] = useState(eventData?.programs?.[0]?.id);
  const selectedDay = eventData?.programs.find((day: any) => day.id === selectedDayId);

  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(eventData?.programs?.[0]?.activities?.[0]?.id);
  const selectedActivity = selectedDay?.activities.find((activity: any) => activity.id === selectedActivityId);

  const [selectedColumn, setSelectedColumn] = useState(0);

  useEffect(() => {
    if (isSuccess) {
      setSelectedDayId(eventData?.programs?.[0]?.id);
      setSelectedActivityId(eventData?.programs?.[0]?.activities?.[0]?.id);
    }

  }, [isFetching]);

  if (isLoading) return <div className='text-center'>Loading...</div>;
  if (error) return <div className='text-center'>Error loading data</div>;

  return (
    <div className="relative min-h-screen h-full bg-brand-darkgray">
      <div
        className="fixed inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${selectedDay?.image || eventData.image})`,
          backgroundAttachment: 'fixed',
          opacity: 0.1
        }}
      >
        <div className="absolute inset-0 bg-brand-darkgray/70 backdrop-blur-sm"></div>
      </div>

      <div className="relative min-h-screen flex flex-col h-full">
        <header className="p-6 text-brand-lightgray">
          <h1 className="sm:text-4xl font-bold mb-2 text-center">{eventData.name}</h1>
        </header>

        <div className="flex-1 flex gap-6 sm:p-6 overflow-hidden">
          <div className={`w-full sm:w-1/4 backdrop-blur-md rounded-2xl py-6 overflow-y-hidden sm:flex flex-col ${selectedColumn === 0 ? 'flex' : 'hidden'}`}>
            <div className="overflow-y-auto flex-1 custom-scroll overflow-x-visible p-6 pl-0 sm:p-0">
              <DayList
                onElementClick={(day: any) => {
                  setSelectedDayId(day.id);
                  setSelectedActivityId(day.activities?.[0]?.id);
                  setSelectedColumn(1)
                }}
                days={eventData.programs.sort((a: any, b: any) => a.dayIndex - b.dayIndex)}
                selectedDayId={selectedDayId}
              />
            </div>
          </div>

          <div className={`w-full sm:w-1/2 backdrop-blur-md rounded-2xl
            overflow-hidden  sm:flex flex-col ${selectedColumn === 1 ? 'flex' : 'hidden'}`}>

            <h2 className="text-xl font-semibold text-brand-lightgray mb-6 text-center px-4">
              <ArrowLeft className='absolute left-0 cursor-pointer text-brand-lightgray sm:hidden' onClick={() => { setSelectedColumn(Math.max(selectedColumn - 1, 0)) }} />
              {selectedDay?.name} |
              <span className="text-sm">{(selectedDay?.date) ? (' ' + moment(new Date(selectedDay?.date)).format('ddd')) : ''} </span>
              <span className="text-sm">{(selectedDay?.date) ? moment(new Date(selectedDay?.date)).format('MMM') : ''} </span>
              <span className="text-sm">{(selectedDay?.date) ? moment(new Date(selectedDay?.date)).format('DD') : ''} </span>
            </h2>

            <div className={`overflow-y-auto custom-scroll px-4 pb-4`}>
              <span className="text-md text-brand-lightgray mb-6">
                {selectedDay?.description}
              </span>

              <div className="flex-1 ">
                <ActivityList
                  onElementClick={(activity: any) => {
                    setSelectedColumn(2);
                    setSelectedActivityId(activity.id)
                  }}

                  activities={selectedDay?.activities || []}
                  selectedActivityId={selectedActivityId} />
              </div>
            </div>
          </div>

          <div className={`w-full sm:w-1/2 bg-brand-darkgray/5 backdrop-blur-md rounded-2xl overflow-hidden flex-1 sm:flex flex-col ${selectedColumn === 2 ? 'flex' : 'hidden'}`}>

            <ActivityDetails activity={selectedActivity} />
            <ArrowLeft className='absolute left-0 cursor-pointer text-brand-lightgray sm:hidden' onClick={() => { setSelectedColumn(Math.max(selectedColumn - 1, 0)) }} />
          </div>

          {/* Inclusions */}
          {/* <div className="w-1/4 bg-brand-darkgray/5 backdrop-blur-md rounded-2xl p-6 overflow-hidden flex flex-col">
            <h2 className="text-xl font-semibold text-brand-lightgray mb-6">
              Inclusions
            </h2>
            <div className="overflow-y-auto flex-1 pr-4 custom-scrollbar">
              <InclusionsList inclusions={[{ id: "6711fdf2310afcbc4b2bb0c7", title: "Demo File", type: "pdf", url: "https://file-examples.com/wp-content/storage/2017/10/file-sample_150kB.pdf" }]} />
            </div>

          </div> */}
        </div>
      </div>
    </div >
  );
}