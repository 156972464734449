import httpSrvc from "../../../services/HttpSrvc";

interface ISendOtpRequest {
  emailOrPhone: string;
}

export const SendOtpRequest = (payload: ISendOtpRequest) => {
  const url = "/oapi/otp";
  return httpSrvc.post(url, payload);
};
