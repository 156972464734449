import * as React from 'react';
import LogoAndText from './components/LogoAndText';
import Waitlist from './components/Waitlist';
import CarbonpaperLogo from "./images/carbonpaper-logo.svg";
import GoogleSignIn from './components/GoogleSignIn';
import appStoreLogo from "./images/ios_app_store.png";
import playStoreLogo from "./images/android_app_store.png";

interface Props {
  name: string
}


class App extends React.Component<Props> {

  render() {
    return (
      <div id="body-wrapper" className='absolute w-full h-full bg-brand-darkgray text-brand-lightgray flex'>
        <div className="w-full p-4 sm:p-0 sm:h-1/2 sm:w-1/2 flex flex-col align-middle justify-center gap-y-12 mx-auto fixed sm:left-1/4 sm:top-1/4 h-full">

          <LogoAndText />

          <div className={'flex flex-row  items-start'}>
            <div className='w-1/2  items-center flex cursor-pointer'>
              <a style={{ margin: 'auto' }}
                href='https://play.google.com/store/apps/details?id=com.carbonpaper.app&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img style={{ height: '50px', margin: 'auto' }} className='w-full h-1/2 flex justify-self-end' src={playStoreLogo} alt="Google Play Store Image" />
              </a>
            </div>
            <div className='w-1/2  items-center flex cursor-pointer'>
              <a style={{ margin: 'auto' }}
                href='https://apps.apple.com/in/app/carbonpaper-app/id6477400555?ign-itscg=30200&ign-itsct=apps_box_promote_link' >
                <img style={{ height: '50px', margin: 'auto' }} className='w-full h-1/2 flex justify-self-end' src={appStoreLogo} alt="App Store Image" />
              </a>
            </div>
          </div>
          <div className="flex flex-row p-2 shadow-xl fixed bottom-0 bg-brand-darkgray text-brand-danger w-96 self-center text-neutral-content">
            <div className='mx-auto'>
              <a className="link link-hover text-center text-brand-lightgray text-sm" href='/contact-us'>Contact us</a>
            </div>
            <div className='mx-auto'>
              <a className="link link-hover text-brand-lightgray text-sm" href='/privacy-policy'>Privacy Policy</a>
            </div>
            <div className='mx-auto'>
              <a className="link link-hover text-brand-lightgray text-sm" href='/terms-of-service'>Terms of Service</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;