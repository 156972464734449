import axios from "axios";
import CONSTANTS from "../config/constants";
import FormData from "form-data";

class HttpSrvc {

  baseURL: string;
  axiosInstance: any;
  timeout!: number;

  constructor(baseUrl: string, timeout: number) {
    this.baseURL = baseUrl;
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      timeout: timeout
    });

  }

  public async postFormData(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "post",
        url: this.baseURL + path,
        params: queryParams,
        headers: { ...headers, "Content-Type": "multipart/form-data" },
        data: data
      };
      const response = await this.axiosInstance.request(config);
      return response;
    }
    catch (err: any) {
      throw err;
    }
  }

  public async post(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "post",
        url: path,
        params: queryParams,
        headers,
        data
      };
      const response = await this.axiosInstance.request(config);
      return response;
    }
    catch (err: any) {
      throw err;
    }
  }

  public async get(path: string, queryParams?: any, headers?: any) {
    try {
      return await this.axiosInstance.request(
        {
          method: "get",
          url: path,
          params: queryParams,
          headers,
        }
      );
    }
    catch (err) {
      throw err;
    }
  }
}

export default new HttpSrvc(CONSTANTS.ENV_CONSTANTS.BASE_URL, CONSTANTS.ENV_CONSTANTS.HTTP_TIMEOUT);