import { string } from "yup";

export interface ISigninRequest {
  emailOrPhone: string;
  password: string;
}

export interface ISigninResponse {
  firstName: string;
  lastName: string;
  emailId: string;
  authToke: string;
}

export interface ISignupRequest {
  emailId: string;
  password: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

export interface ISignupResponse extends Omit<ISigninRequest, "password"> {
  authToken: string;
}

export interface IVerifyOtpRequest {
  id: string;
  otp: number;
}

export interface IUserCheck {
  exists: boolean;
  medium: string | null;
}

enum FormFields {
  EmailOrPhone = "emailOrPhone",
  EmailId = "emailId",
  Password = "password",
  FirstName = "firstName",
  LastName = "lastName",
  MobileNumber = "mobileNumber",
  Otp = "otp",
}

export interface IFormField {
  type: string;
  id: string;
  name: FormFields;
  label: string;
  required: boolean;
  disabled?: boolean;
  errorType?: any;
}

export const FormFieldObject = {
  emailOrPhone: {
    type: "text",
    id: FormFields.EmailOrPhone,
    name: FormFields.EmailOrPhone,
    label: "Email or Phone Number",
    required: true,
    errorType: string().required("Required *"),
  },
  emailId: {
    type: "text",
    id: FormFields.EmailId,
    name: FormFields.EmailId,
    label: "Email Id",
    required: true,
    errorType: string().required("Required *"),
  },
  password: {
    type: "password",
    id: FormFields.Password,
    name: FormFields.Password,
    label: "Password",
    required: true,
    errorType: string().required("Required *"),
  },
  firstName: {
    type: "text",
    id: FormFields.FirstName,
    name: FormFields.FirstName,
    label: "First Name",
    required: true,
    errorType: string().required("Required *"),
  },
  lastName: {
    type: "text",
    id: FormFields.LastName,
    name: FormFields.LastName,
    label: "Last Name",
    required: false,
  },
  mobileNumber: {
    type: "text",
    id: FormFields.MobileNumber,
    name: FormFields.MobileNumber,
    label: "Mobile Number",
    required: true,
    errorType: string().required("Required *"),
  },
  otp: {
    type: "password",
    id: FormFields.Otp,
    name: FormFields.Otp,
    label: "OTP",
    required: true,
    errorType: string().required("Required *"),
  },
};
