import httpSrvc from "../../../services/HttpSrvc";
import { ISigninRequest } from "../model/LoginModel";

export const BulkUploadRequest = (eventId: string, data: any, authToken: string) => {
  const url = "/api/events/{eventId}/bulk-image-upload";
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };
  return httpSrvc.postFormData(url.replace("{eventId}", eventId), data, null, headers);
};
