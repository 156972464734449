import React from "react";
import { useRouteError, Link } from "react-router-dom";
import LogoAndText from "./LogoAndText";

import CarbonpaperLogo from "./../images/carbonpaper-full-logo.png";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(`${error?.statusText || error?.message}`);

  return (
    <div id="error-page" className="absolute w-full h-full bg-brand-darkgray text-brand-lightgray text-center">
      <div className="h-1/2 w-1/2 flex mx-auto fixed left-1/4 top-1/4">
        <LogoAndText logo={CarbonpaperLogo}/>
      </div>
      <div className="absolute w-1/2 left-1/4 bottom-1/4">        
        <h1 className="text-4xl">Sorry, an unexpected error has occurred.</h1>
        <br />
        <Link to={`/`}>
          <p>Click here to go to homepage.</p>
        </Link>
      </div>
    </div>
  );
}