import React from 'react';
// import { format } from 'date-fns';
import { Calendar } from 'lucide-react';
import moment from 'moment';
// import clsx from 'clsx';
// import { Day } from '../types';

interface DayListProps {
  days: any[];
  selectedDayId: string | null;
  // onSelectDay: (dayId: string) => void;
  // setSelectedActivityId: (dayId: string) => void;
  onElementClick: (day: any) => void;
}

export function DayList({ days, selectedDayId, onElementClick }: DayListProps) {

  return (
    <div className="space-y-3">
      {days.map((day, index) => (
        <div key={index} className={`flex  ${selectedDayId === day.id ? 'bg-gradient-to-t from-brand-darkgray/0 via-brand-lightgray/10 to-brand-darkgray/0' : ''}`}>
          <div className={`content-center ${selectedDayId === day.id ? 'w-28' : 'w-36'}`}>
            <div className=" flex flex-col py-4 text-center">
              <div className={`${selectedDayId === day.id ? 'text-2xl' : 'text-xl'} text-brand-lightgray font-bold`}>
                {moment(new Date(day.date)).format('ddd')}
              </div>
              <div className={`${selectedDayId === day.id ? 'text-md' : 'text-sm'} text-brand-lightgray`}>
                {moment(new Date(day.date)).format('MMM')}
              </div>
              <div className={`${selectedDayId === day.id ? 'text-2xl' : 'text-xl'} text-brand-lightgray font-bold`}>
                {moment(new Date(day.date)).format('DD')}
              </div>
            </div>
          </div>
          <button
            key={day.id}
            onClick={() => { onElementClick(day) }}
            className={
              `relative flex-auto border border-t-brand-fadegray border-l-brand-fadegray border-r-brand-darkgray border-b-brand-darkgray border-2
              text-left ${selectedDayId === day.id ? 'h-36 w-72' : 'w-64'}`
            }>
            <div className="absolute top-0 w-full h-full ">
              <img
                src={day.image}
                className={`w-full h-full ${selectedDayId === day.id ? 'opacity-90' : 'opacity-30'}  object-cover transform hover:scale-105 transition-transform duration-500`}
              />
            </div>
            <div className={`absolute flex flex-col top-0 w-full h-full p-2 overflow-hidden content-between bg-brand-darkgray/10`}>
              <h3 className="flex-1 font-semibold text-xl text-brand-lightgray custom-text-shadow ">{day.name}</h3>

              <h3 className="flex-1 font-semibold text-sm text-brand-lightgray content-end custom-text-shadow">{day.subtitle}</h3>
            </div>
          </button>
        </div>
      ))}
    </div>
  );
}