const EMAIL_REGEX = new RegExp("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$");

const MOBILE_PHONE_REGEX = new RegExp("^[1-9]{1}[0-9]{9}$");

const MOBILE_OR_EMAIL_REGEX = new RegExp(EMAIL_REGEX.source + "|" + MOBILE_PHONE_REGEX.source);

export default {
  EMAIL_REGEX,
  MOBILE_PHONE_REGEX,
  MOBILE_OR_EMAIL_REGEX,
  ENV_CONSTANTS
}