import { useEffect, useState } from "react";

export const useTimer = ({ startTime = 30 } = {}) => {
  const start = startTime;
  const [time, setTime] = useState<any>(start);

  useEffect(() => {
    const timer = time > 0 && setInterval(() => setTime(time - 1), 1000);
    return () => clearInterval(timer as any);
  }, [time]);

  return [time, setTime];
};
