import { Calendar, Clock, MapPin } from 'lucide-react';
import moment from 'moment';
import React from 'react';

interface ActivityDetailsProps {
  title: string;
  description: string;
  time: string;
}

const ActivityDetails = ({ activity }: any) => {

  return (
    <div className="flex overflow-hidden activity-details">

      <div className="overflow-y-auto flex-1 custom-scroll px-6">
        {activity ? (
          <div className={`relative overflow-hidden`}>
            <h3 className="text-xl font-semibold text-brand-lightgray mb-6 text-center">{activity.name}</h3>
            <div className='overflow-y-auto flex-1 '>
              {
                activity.image && (
                  <div className={`relative w-full h-52 mb-6 max-h-52`}>
                    <img
                      src={activity.image}
                      alt={activity.name}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                )
              }
              <div className="text-lg font-semibold text-brand-lightgray custom-text-shadow mb-2">
                <span>{activity.subtitle}</span>
              </div>

              <div className="flex items-center gap-2 mb-2 text-brand-lightgray">
                <Calendar className="w-4 h-4" />
                <span>
                  {moment.utc(new Date(activity.startDateTime)).format("hh:mm A")}
                </span>
              </div>

              <div className="flex items-center gap-2 mb-2 text-brand-lightgray">
                <Clock className="w-4 h-4" />
                {`${parseFloat(moment.duration(moment.utc(activity.endDateTime).diff(moment.utc(activity.startDateTime))).asHours().toFixed(2))} hrs`}
              </div>

              <p className="text-md text-brand-lightgray mb-6">{activity.description}</p>

            </div>
          </div>
        ) : (
          <p className="text-sm text-brand-lightgray">Select an activity to see details</p>
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;