import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserDetails {
  emailId: string | null;
  firstName: string | null;
  lastName: string | null;
  mobileNumber: string | null;
  authToken: string | null;
}

const initialState: UserDetails = {
  emailId: null,
  firstName: null,
  lastName: null,
  mobileNumber: null,
  authToken: null,
};

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
