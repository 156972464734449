import React from "react";
import CarbonpaperLogo from "./../images/carbonpaper-logo.svg";


const PrivacyPolicy = () => {
  return (
    <div className="bg-brand-darkgray" style={styles.complainacePage}>
      <div style={styles.headerContainer}>
        <a style={styles.headerContainer} href="/">
          <img className="w-20" src={CarbonpaperLogo} alt="CP" />
          <p style={styles.headerText}>DIGIALT CARBONPAPER APPS PRIVATE LIMITED</p>
        </a>
      </div>
      <div style={styles.complainaceContent}>
        <div style={styles.contentContainer}>
          <p style={styles.contentHead}>Privacy Policy</p>
          <div style={styles.contentSeparator}></div>
          <p style={styles.updatedDate}>Last updated on Mar 30th 2024</p>
          <p style={styles.contentText}>
            This privacy policy sets out how DIGIALT CARBONPAPER APPS PRIVATE LIMITED uses and protects any information that you give when you visit their website and/or agree to purchase from them.
          </p>
          <p style={styles.contentText}>
            DIGIALT CARBONPAPER APPS PRIVATE LIMITED is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
          </p>
          <p style={styles.contentText}>
            DIGIALT CARBONPAPER APPS PRIVATE LIMITED may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you adhere to these changes.
          </p>
          {/* Similar structure for remaining content */}
          <p style={styles.contentText}><strong>We may collect the following information:</strong></p>
          <ul style={{ listStyleType: 'inherit', padding: 0 }}>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>Name</p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>Contact information including email address</p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>Demographic information such as postcode, preferences, and interests</p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>Other information relevant to customer surveys and/or offers</p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>Event Information: We collect information about the events you create or participate in, including event details, guest lists, and any activities or services booked through our platform.</p>
            </li>
          </ul>
          <p style={{ ...styles.contentText, fontWeight: 'bold' }}>What we do with the information we gather</p>
          <ul style={{ listStyleType: 'inherit', padding: 0 }}>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                To Provide Services: We use your information to create and manage events, facilitate communication between event hosts and guests, process payments, and deliver other services you request.
              </p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>Internal record keeping.</p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>We may use the information to improve our products and services.</p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                Analytics: We analyze usage data to improve our services, enhance security, and optimize performance.
              </p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                We may periodically send promotional emails about new products,
                special offers or other information which we think you may
                find interesting using the email address which you have provided.
              </p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                From time to time, we may also use your information to contact you for market research purposes. We may contact you by email,
                phone, fax or mail. We may use the information to customise the website according to your interests.
              </p>
            </li>
          </ul>
          <p style={styles.contentText}>
            We are committed to ensuring that your information is secure.
            In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
          </p>
          {/* Cookie Policy */}
          <p style={{ ...styles.contentText, fontWeight: 'bold' }}>How we use cookies</p>
          <p style={styles.contentText}>
            A cookie is a small file which asks permission to be placed on your computer's hard drive.
            Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site.
            Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs,
            likes, and dislikes by gathering and remembering information about your preferences.
          </p>
          {/* Privacy Control */}
          <p style={{ ...styles.contentText, fontWeight: 'bold' }}>Controlling your personal information</p>
          <ul style={{ listStyleType: 'inherit', padding: 0 }}>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate
                that you do not want the information to be used by anybody for direct marketing purposes.
              </p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                If you have previously agreed to us using your personal information for direct marketing purposes,
                you may change your mind at any time by writing to or emailing us.
              </p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                Third-Party Service Providers: We may share your information with third-party service providers who assist us in delivering our services,
                such as payment processors, email providers, and analytics partners.
              </p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                Legal Compliance: We may disclose your information when required by law, legal process, or governmental request, or to protect our rights, property, or safety or that of others.
              </p>
            </li>
            <li style={{ ...styles.contentText, display: 'list-item' }}>
              <p style={styles.contentText}>
                Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction. We will notify you via email or prominent notice on our website of any change in ownership or use of your personal information.
              </p>
            </li>
          </ul>
          <p style={styles.contentText}>
            We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so.
            We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
          </p>

          <p style={styles.contentText}>
            If you believe that any information we are holding on you is incorrect or incomplete,
            please write to Unit 101, Oxford Towers, 139, HAL Old Airport Rd, Hulsur Bazaar, Bangalore North Bengaluru KARNATAKA 560008 or contact us at support@carbonpaper.app as soon as possible.
            We will promptly correct any information found to be incorrect.
          </p>
        </div>
      </div>

    </div>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
  },
  headerText: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#fff',
    margin: '0 0 0 18px',
  },
  complainacePage: {
    // background: '#0a1d38',
    fontFamily: 'Lato, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  complainaceContent: {
    background: '#ffffff',
    borderRadius: '32px 32px 0 0',
    padding: '32px 24px',
  },
  contentHead: {
    fontSize: '24px',
    lineHeight: '30px',
    color: '#213554',
    margin: 0,
    fontWeight: 700,
  },
  contentContainer: {
    margin: '0 auto',
    maxWidth: '1000px',
  },
  contentSeparator: {
    width: '28px',
    height: '5px',
    backgroundColor: '#213554',
    marginTop: '16px',
  },
  updatedDate: {
    margin: '16px 0 0',
    color: '#213554ab',
    fontWeight: 700,
  },
  contentText: {
    color: '#515978',
    margin: '16px 0 0',
    fontSize: '14px',
    lineHeight: '20px',
  },
  merchantLogo: {
    width: '74.6px',
    height: '64px',
    background: '#fefefe',
    boxShadow: '0px 0px 8px #00000040',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    background: '#0a1d38',
    boxShadow: '11.0003px 22.0007px 53.9016px rgba(0, 0, 0, 0.1), 22.0007px 11.0003px 53.9854px -2.03719px rgba(255, 255, 255, 0.1)',
    borderRadius: '50%',
  },
  logoText: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '32px',
  },
};

export default PrivacyPolicy;