import React from 'react';
import { Clock } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';

interface ActivityListProps {
  activities: any[];
  selectedActivityId: string | null;
  // setSelectedActivityId: (dayId: string) => void;
  onElementClick: (activity: any) => void;
}

export function ActivityList({ activities, onElementClick, selectedActivityId }: ActivityListProps) {

  const allhours = Array.from({ length: 24 }, (_, index) =>
    moment().startOf('day').add(index, 'hours').format('hh A')
  );

  const groupedActivities: any = {};

  activities.forEach((activity: any) => {

    if (!activity)
      return;
    const hour = moment.utc(activity.startDateTime).format('hh A');


    if (!groupedActivities[hour]) {
      groupedActivities[hour] = [];
    }
    groupedActivities[hour].push(activity);
  });

  const sortedHourKeys = Object.keys(groupedActivities).sort((a, b) => {
    return moment(a, 'hh A').diff(moment(b, 'hh A'));
  });

  const firstSortedHourIndex = allhours.findIndex(hour => hour === sortedHourKeys[0]);
  const lastSortedHourIndex = allhours.findIndex(hour => hour === sortedHourKeys[sortedHourKeys.length - 1]);
  const filteredAllHours = allhours.slice(firstSortedHourIndex, lastSortedHourIndex + 1);

  if (activities.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-center">
        <Clock className="w-12 h-12 text-brand-lightgraybackup mb-4" />
        <p className="text-brand-lightgraybackup">
          No activities scheduled for this day yet.
          <br />
          Take some time to relax and explore!
        </p>
      </div>
    );
  }

  return (
    <div className="relative mt-2">
      {/* Timeline line */}
      {/* <div className="absolute left-0 top-0 bottom-0 w-0.5 bg-brand-lightblue/30" /> */}

      <AnimatePresence mode="wait">
        <div className="space-y-8">
          {
            filteredAllHours.map((hour, index) => {
              if (!groupedActivities[hour]) {
                return null;
              }
              groupedActivities[hour] = groupedActivities[hour].sort((a: any, b: any) => {
                return moment(a.startDateTime).diff(moment(b.startDateTime))
              });
              return (
                <div key={hour} className="space-y-4 ">
                  <div className='flex flex-row justify-between'>
                    <h2 className="text-lg text-brand-lightgray">{hour}</h2>
                    <div className='flex flex-row items-start justify-end leading-3'>
                      {
                        groupedActivities[hour].length > 1 && groupedActivities[hour].map((activity: any, index: number) => {
                          return <span key={index} className={`text-brand-fadegray text-2xl whitespace-pre`}>
                            {` _`}
                          </span>
                        })
                      }
                    </div>
                  </div>

                  <div className={`flex overflow-x-auto overflow-y-hidden space-x-4 custom-scroll ${groupedActivities[hour].find((activity: any) => {
                    return selectedActivityId === activity.id
                  }) ? 'bg-gradient-to-r from-brand-darkgray/0 via-brand-lightgray/50 ' : ''}`}>
                    {
                      groupedActivities[hour].map((activity: any, index: number) => (
                        <motion.div
                          key={activity.id}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 20 }}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                          className={`min-w-[95%]`} >

                          <div className="relative cursor-pointer h-36 rounded-xl overflow-hidden"
                            onClick={() => onElementClick(activity)}>
                            <div className="relative flex w-full h-full">
                              <img
                                src={activity.image}
                                alt={activity.title}
                                className={`w-full h-full object-cover ${selectedActivityId === activity.id ? 'border-b-2' : 'opacity-70'}`}
                              />
                            </div>
                            <div className="top-0 absolute flex flex-col w-full h-full p-4 bg-brand-darkgray/60 content-between">
                              <div className="flex flex-1 items-center text-lg text-brand-lightgray font-bold flex-row">
                                <div className='flex-auto text-brand-lightgray self-start'>
                                  {activity.name}
                                </div>
                                <div className='text-sm text-brand-lightgray text-right'>
                                  <div className={`px-2`}>
                                    {moment.utc(new Date(activity.startDateTime)).format("hh:mm A")}
                                  </div>
                                  <div className={`flex items-center justify-end px-2`}>
                                    <span className={`flex items-center inline-block self-end`}>
                                      <Clock className="w-4 h-4 pr-1" />
                                      {`${parseFloat(moment.duration(moment.utc(activity.endDateTime).diff(moment.utc(activity.startDateTime))).asHours().toFixed(2))} hrs`}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <h3
                                className="flex flex-1 font-semibold text-lg text-brand-lightgray items-end"
                              >
                                {activity.subtitle}
                              </h3>
                              {/* <p className="text-lg text-brand-lightgray leading-relaxed line-clamp-3">
                              {activity.description}
                            </p> */}
                            </div>
                          </div>
                        </motion.div>
                      ))
                    }
                  </div>

                </div>
              )
            })
          }
        </div>
      </AnimatePresence>
    </div>
  );
}