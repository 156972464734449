import React, { ComponentPropsWithoutRef } from 'react';

interface IFieldProps extends ComponentPropsWithoutRef<"input"> {
  register: any;
  error: any;
  name: string;
  value: any;
  label?: string;
};

const Field = (props: IFieldProps) => {
  const { register, error, name, label, value, ...rest } = props;
  const labelString = `${label} ${rest.required ? '*' : ''}`;

  switch (rest.type) {
    case "text":
    case "password":
    case "number":
      return (
        <div className="form-control w-full h-16">
          <label className="label">
            <div className="label-text h-2">{value && labelString}</div>
          </label>
          <input {...register(name)} placeholder={labelString} className="input input-bordered w-full p-2
              bg-brand-lightgray border-brand-darkgray border-0 border-b-2 focus:outline-none rounded-none disabled:opacity-70 disabled:bg-inherit" autoComplete='off' {...rest}
          />
          <label className="label">
            <span className="label-text-alt text-right w-full h-2 text-brand-danger">{
              error && error.message
            }</span>
          </label>
        </div>
      );
      break;

    default: return <></>
      break;
  }
}

export default Field;